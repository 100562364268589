export default [
    {
        header: "How To Use Scanner+",
        content: "Basically, using Scanner+ isn’t much different than taking a picture with your phone’s camera. Still, of course, there are some extra features that help you to turn your phone’s camera into a powerful scanner.! ",
        image: "how-to-use-scanner.png",
        blogId: "how-to-use-scanner-plus"
    },
    {
        header: "Advantages of OCR Technology",
        content: "OCR (optical character recognition) technology has been developed to convert written & printed texts into machine-readable digital texts. Today, OCR technology doesn’t only help your devices to understand any kind of written or printed text but also provides advantages for you in every aspect of life.",
        image: "advantages-of-ocr.png",
        blogId: "advantages-of-ocr-technology"
    },
    {
        header: "What is OCR?",
        content: "How do you know what you see is actually what you see? You know the difference between the Chinese alphabet and the Greek one even though you cannot read them. But how? There are still ongoing scientific debates on human perception today, but the recognition for the machines is rather easy. ",
        image: "what-is-ocr.png",
        blogId: "what-is-ocr"
    },
    {
        header: "Keep All Your Documents Secure",
        content: "We know that some of the documents that we need to scan are confidential or private, and we’d like to protect the privacy of our users as best as we can while still offering them the ease of use.",
        image: "keep-docs-secure.png",
        blogId: "keep-all-your-documents-secure"
    },

    {
        header: "Increase Your Productivity in School",
        content: "Being cooped up at home with all the craziness going on all over the world can really hinder your motivation and will. You can procrastinate as much as you want, but all that’s going to do is pile all the work up like a mountain and frustrate you even more.",
        image: "increase-productivity.png",
        blogId: "increase-your-productivity-in-school"
    },
    {
        header: "Support Homeschool",
        content: "Say goodbye to the big piles of papers around your work area thanks to mobile scan. Let’s take a look at how Scanner+ can help you with studying from home...",
        image: "support-homeschool.png",
        blogId: "support-homeschool"
    },
    {
        header: "2021 E-Signature Guide",
        content: "Forget about the times you had to print out all the pages, sign them all, and send them back through a fax machine. Now, you can stop all of these time-consuming processes thanks to the best scanner app for iphone: Scanner+.",
        image: "2021-esignature.png",
        blogId: "importance-of-digital-signature-in-remote-life"
    },
    {
        header: "Scanner+ for Business & HR Management",
        content: "Especially in this period where we all work from home, it would be a long haul for the HR department to carry the necessary documents home. Luckily, there are some productivity apps to sort these problems out, such as Scanner+",
        image: "scanner-for-business-hr.png",
        blogId: "scanner-plus-for-business-and-hr-management"
    },
    {
        header: "Organize Your Business Cards with Scanner+",
        content: "This ultimate scanner app also can act as a business card app. It allows you to scan all those different types of business cards, so that you won’t have to carry them in different wallets. Just scan the cards, save them to your mobile phone, tablet, computer to display anytime, anywhere you want. ",
        image: "organize-business-cards.png",
        blogId: "cant-find-your-business-cards"
    },
    {
        header: "Can’t find your shopping receipts? Here’s the solution: Download the iPhone Scanner+ app",
        content: "Scanner+, a mobile scanner app, helps you to keep your receipts always in your pockets. Yes, we say “always” because you’ll be able to keep all your receipts on your phone, not only in the pocket of the pair of jeans that you wore while shopping.",
        image: "cant-find-shopping-receipts.png",
        blogId: "cant-find-your-shopping-receipts"
    }
]